<script setup lang="ts">
import { getSrcSetForMedia } from '@shopware-pwa/helpers-next';

import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

const props = defineProps<{
  content: {
    data: {
      grid: {
        // [x: string]: string;
        id: string;
        productImageSrc: string;
        productName: string;
        link: string;
        mediaSrc: string;
        productCoverUrl: string;
        leftSlotBackground: string;
        mediaUrl: string;
        backgroundColor: string;
        product: {
          seoUrls: {
            seoPathInfo: string | null;
          }[];
          cover: {
            media: {
              thumbnails: string;
              alt: string;
              title: string;
            };
          };
        };
        media: {
          alt: string;
          title: string;
        };
      }[];
    };
    translated: {
      config: {
        gridSettings: {
          value: {
            title: string;
            autoRotate: boolean;
            navigation: boolean;
            rotationSpeed: number;
          };
        };
      };
    };
  };
}>();

type SliderData = {
  id: string;
  productImageSrc: string;
  productName: string;
  link: string;
  mediaSrc: string;
  altText: string;
  titleText: string;
  leftSlotBackground: string;
  srcsetLeft: string;
  srcsetRight: string;
  altTextProduct: string;
  titleTextProduct: string;
};

// FIXME: write func to get link to product :link
// const productUrl = (product: IProduct) => {
//   let urlToProductPage = '';

//   if (route.matched[0].meta.pathPrefix) {
//     urlToProductPage = route.matched[0].meta.pathPrefix + getProductUrl(product);
//     return urlToProductPage;
//   } else {
//     return getProductUrl(product);
//   }
// };

const sliderData: SliderData[] = [];

props.content.data.grid.forEach((item) => {
  sliderData.push({
    id: item.id,
    productImageSrc: item.productCoverUrl,
    productName: item.productName,
    link: item.product?.seoUrls
      ? `/${locale.value}/${item?.product?.seoUrls[0]?.seoPathInfo}`
      : '',
    mediaSrc: item.mediaUrl,
    leftSlotBackground: item.backgroundColor,
    srcsetRight:
      item.mediaUrl && item.media ? getSrcSetForMedia(item.media as any) : '',
    srcsetLeft: item.product?.cover?.media?.thumbnails
      ? getSrcSetForMedia(item.product?.cover?.media as any)
      : '',
    altText: item.media ? item.media?.alt : '',
    titleText: item.media ? item.media?.title : '',
    altTextProduct: item.product?.cover?.media?.alt || '',
    titleTextProduct: item.product?.cover?.media?.title || '',
  });
});

const rotationTempo = computed(() => {
  let x = props.content.translated.config.gridSettings.value.rotationSpeed;
  //TODO: investiage tempo
  return x * 1000;
});
</script>

<template>
  <div>
    <HighlightSlider
      :slider-data="sliderData"
      :title="props.content.translated.config.gridSettings.value.title"
      :autoplay="props.content.translated.config.gridSettings.value.autoRotate"
      :autoplay-speed="rotationTempo"
      :arrows="props.content.translated.config.gridSettings.value.navigation"
      :loop="true"
      :dots="false"
      :breakpoints="{
        '(max-width: 1023px)': {
          slides: {
            origin: 0,
            perView: 1.25,
            spacing: 20,
          },
        },
        '(min-width: 1024px)': {
          slides: {
            origin: 0,
            perView: 1,
            spacing: 0,
          },
        },
      }"
    />
  </div>
</template>
